import React, {useMemo} from 'react';
import {Field, Form, Formik} from 'formik';
import {Col, Container, FormControl, FormLabel, Row} from 'react-bootstrap';
import AutoPersistToken from '../../../elements/Formik/AutoPersistToken';
import useFormPersistedValues from '../../../../hooks/useFormPersistedValues';
import PropTypes from 'prop-types';
import SubmitButton from '../../../elements/Form/SubmitButton';
import SelectField from "../../../elements/ReactSelect/SelectField.jsx";
import {addendumTypesOptions} from "../../../../utils.js";
import {format} from "date-fns";

const DateRangeAndAddendumTypeSelectionWidget = ({
    onSubmit,
}) => {
    const formName = 'fscFormSelection';
    const initialFormValues = useFormPersistedValues(formName);
    const todayFormatted = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);

    return (
        <Formik initialValues={initialFormValues || {
            startDateTime: '',
            endDateTime: '',
            addendumType: '',
        }} validate={values => {
            const errors = {};
            if (values.startDateTime === '') {
                errors.startDateTime = 'Моля, въведете начална дата!';
            }
            if (values.endDateTime === '') {
                errors.endDateTime = 'Моля, въведете крайна дата!';
            }
            if (values.addendumType === '') {
                errors.addendumType = 'Моля, избетере приложение!';
            }
            return errors;
        }} onSubmit={values => onSubmit({
            ...values,
            ...(values.startDateTime === '' ? {
                startDateTime: null,
            } : {}),
            ...(values.endDateTime === '' ? {
                endDateTime: null,
            } : {}),
        })}>
            {({isSubmitting}) => (
            <Container as={Form} className="gx-0">
                <Row className="row-cols-1 row-cols-xl-3 g-3 align-items-end mt-2">
                    <Col>
                        <FormLabel htmlFor="addendumType" >Приложение</FormLabel>
                        <Field name="addendumType" component={SelectField}
                               isClearable={true}
                               options={addendumTypesOptions}
                               label="КФН Приложение"
                               placeholder="Изберете приложение"
                               disabled={isSubmitting}/>
                    </Col>
                    <Col>
                        <FormLabel htmlFor="startDateTime">Начална дата</FormLabel>
                        <FormControl as={Field} type="date" name="startDateTime" id="startDateTime"
                                     max={todayFormatted} disabled={isSubmitting} />
                    </Col>
                    <Col>
                        <FormLabel htmlFor="endDateTime">Крайна дата</FormLabel>
                        <FormControl as={Field} type="date" name="endDateTime" id="endDateTime"
                                     max={todayFormatted} disabled={isSubmitting} />
                    </Col>
                    <Col>
                        <SubmitButton>Изготвяне</SubmitButton>
                    </Col>
                </Row>
                <AutoPersistToken formName={formName}/>
            </Container>
            )}
        </Formik>
    );
};

DateRangeAndAddendumTypeSelectionWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default DateRangeAndAddendumTypeSelectionWidget;
