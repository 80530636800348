import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import {getJournalUrl} from '../../../url';
import Content from '../../partials/Content.jsx';
import DateRangeSelectionWidget from "../../widgets/Report/Selection/DateRangeSelectionWidget.jsx";

const Journal = () => {
    return (<Content title="Дневник на Застрахователния Брокер">
        <Row>
            <Col>
                <Card outline>
                    <DateRangeSelectionWidget onSubmit={values => window.open(getJournalUrl(
                        values.startDateTime + ' 00:00:00',
                        values.endDateTime + ' 23:59:59',
                        true,
                        'journalReportFormDateRange'
                    ))} />
                </Card>
            </Col>
        </Row>
    </Content>);
};

export default Journal;
