export const installmentCounts = [1, 2, 4];
export const installmentColors = {
    1: 'success',
    2: 'info',
    4: 'primary',
};
export const policyStatusColors = {
    ACTIVE: 'success',
    CANCELLED: 'dark',
    TERMINATED: 'danger',
};
