import {getToken} from './authStorage.js';

const getAuthenticatedUrl = (path, download) => import.meta.env.VITE_API_BASE_URL  + path +
    '?bearer=' + getToken() + (download ? '&download=1' : '');

export const getPolicyUrl = (id, download) =>
    getAuthenticatedUrl('print/policy/' + id, download);
export const getPolicyAdditionalUrl = (id, download) =>
    getAuthenticatedUrl('print/policy/additional/' + id, download);

export const getVehicleUrl = (id, download) =>
    getAuthenticatedUrl('print/vehicle/' + id, download);

export const getVoucherUrl = (id, download) =>
    getAuthenticatedUrl('print/voucher/' + id, download);

export const getGreenCardUrl = (id, download) =>
    getAuthenticatedUrl('print/green-card/' + id, download);

export const getInsuranceCompanyReportUrl = (
    insuranceCompanyId,
    weekCode,
    startDateTime,
    endDateTime,
    filterPaidPremiumFromClient,
    filterUnpaidPremiumToIC,
    download) =>
    getAuthenticatedUrl(
        'report/insurance-company/' + insuranceCompanyId + '/' + weekCode +'/' + startDateTime +
         '/' + endDateTime + '/' + filterPaidPremiumFromClient + '/' + filterUnpaidPremiumToIC,
        download
    );

export const getFscReportUrl = (addendumType, startDateTime, endDateTime, download) =>
    getAuthenticatedUrl(
        'report/fsc-file/' + addendumType + '/'  + startDateTime +  '/' + endDateTime, download
    );

export const getInsuranceCompanyReportByPaymentDateToBrokerUrl = (
    insuranceCompanyId,
    weekCode,
    startDateTime,
    endDateTime,
    filterUnpaidPremiumToIC,
    download) =>
    getAuthenticatedUrl(
        'report/insurance-company-by-payment-date-to-broker/' + insuranceCompanyId + '/' + weekCode +'/'
        + startDateTime +'/' + endDateTime+ '/' + filterUnpaidPremiumToIC, download
    );

export const getJournalUrl = (startDateTime, endDateTime, download) =>
    getAuthenticatedUrl(
        'report/journal/' + startDateTime +  '/' + endDateTime, download
    );

export const getUserLimitAuditReportUrl = (userLimitId, download) =>
    getAuthenticatedUrl('report/user-limit-audit/' + userLimitId , download);

export const getBsoProtocolUrl = (id, download) =>
    getAuthenticatedUrl('bso-protocol/' + id + '/print', download);
