import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, Row} from 'react-bootstrap';
import SelectField from "../../elements/ReactSelect/SelectField.jsx";
import {policyStatusOptions} from "../../../utils.js";

const PolicyStatusWidget = ({
    onSubmit,
}) => {
    return (
        <Formik
            initialValues={{
                policyStatus: 'ACTIVE',
            }}
            validate={values => {
                const errors = {};
                if (!values.policyStatus) {
                    errors.policyStatus = 'Моля, изберете статус на полицата!';
                }
                return errors;
            }}
            onSubmit={values => onSubmit({...values})}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0 ms-0 me-0">
                    <Row className="row-cols-12">
                        <Col>
                            <Field name="policyStatus" component={SelectField} options={policyStatusOptions}
                                   label="Статус на полица" />
                        </Col>
                        <Col>
                            <Button variant="success" type="submit" disabled={isSubmitting}>
                                Запиши
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Formik>
    );
};

PolicyStatusWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
export default PolicyStatusWidget;
