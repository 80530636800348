import React, {useState} from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import {
    useLazyReportFscQuery, useListFscCategoriesQuery,
} from '../../../features/apiSlice';
import ErrorWidget from '../../widgets/ErrorWidget';
import Alert from '../../elements/ReactBootstrap/Alert';
import Content from '../../partials/Content.jsx';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import DateRangeAndAddendumTypeSelectionWidget
    from "../../widgets/Report/Selection/DateRangeAndAddendumTypeSelectionWidget.jsx";
import PremiumAmount from "../../elements/Values/PremiumAmount.jsx";
import {sum} from "../../../utils.js";
import {getFscReportUrl} from "../../../url.js";

const Fsc = () => {
    const [addendumType, setAddendumType] = useState('');

    const [generateFsc, {
        data: report,
        error,
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
    }] = useLazyReportFscQuery();

    const {
        data: fscCategories
    } = useListFscCategoriesQuery();

    let filteredFscCategories = [];

    if (isSuccess) {
        filteredFscCategories = fscCategories
            .map(fscCategory => ({
                ...fscCategory,
                addendumTypes: fscCategory.addendumTypes
                    .filter(type => type.includes(addendumType))
            }))
            .filter(fscCategory => fscCategory.addendumTypes.length > 0)
    }

    return (<Content title="Отчет за Комисия по финансов надзор">
        <Row className="row-cols-1 gy-3 flex-grow-1 flex-column">
            <Col>
                <DateRangeAndAddendumTypeSelectionWidget onSubmit={values => {
                    setAddendumType(values.addendumType);
                    window.open(getFscReportUrl(
                        values.addendumType,
                        values.startDateTime,
                        values.endDateTime,
                        true))
                    return generateFsc(values);
                }} />
            </Col>
            <Col className="flex-grow-1">
                {isUninitialized && <Alert variant="secondary"
                                           heading="Моля, изберете критерии за изготвянето на отчета." />}
                {isError && <ErrorWidget error={error} />}
                {isLoading && <Card><SmallSpinner /></Card>}
                {isSuccess && <Table striped bordered responsive>
                    <thead>
                    <tr>
                        <th rowSpan='2' nowrap>Вид застраховка</th>
                        <th colSpan='3' >ОБЩО</th>
                    </tr>
                    <tr>
                        <th>брой</th>
                        <th>премия</th>
                        <th>комисион</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredFscCategories.map(fscCategory => {
                        const currentFscReport = report.filter(reportRow =>
                            reportRow.fscId === fscCategory.id
                        );
                        return (<tr key={fscCategory.id}>
                            <th title={fscCategory.name} style={{whiteSpace: 'nowrap'}}>{fscCategory.name}</th>
                            <td>{sum(currentFscReport.map(currentFscReportRow => currentFscReportRow.count))}</td>
                            <td><PremiumAmount amount={sum(currentFscReport.map(
                                currentFscReportRow => currentFscReportRow.premium === undefined ||
                                currentFscReportRow.premium === null ? 0 : currentFscReportRow.premium
                            ))} /></td>
                            <td><PremiumAmount amount={sum(currentFscReport.map(
                                currentFscReportRow => currentFscReportRow.comm === undefined ||
                                currentFscReportRow.comm === null ? 0 : currentFscReportRow.comm
                            ))} /></td>
                        </tr>);
                    })}
                    </tbody>
                </Table>}
            </Col>
        </Row>
    </Content>);
};

export default Fsc;
